.ant-layout-header .trigger {
  padding: 0 20px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-layout-header .trigger:hover {
  color: #1890ff;
}

.ant-layout-header .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout {
  min-height: 100vh;
}

#form-login label {
  color: #fff;
}

.ant-table table {
  border-collapse: collapse;
  font-size: 90%;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 18px;
}

.ant-table-thead tr th {
  background-color: #001529 !important;
  color: #fff;
}

.ant-table table {
  font-size: 84%;
}

@media all and (max-width: 480px) {
  .custom-class {
    width: 100% !important;
    display: block;
  }
}
table.table-simple {
  width: 100%;
}
table.table-simple td,
table.table-simple th {
  border: 1px solid #ddd;
  padding-left: 5px;
}


.table-color{
  border-collapse: collapse;
}

/* .table-color table, */
.table-color td,
.table-color th {
  border: 1px solid #ddd;
}

.ql-editor {
  height: 200px !important;
}

.ant-form-item-label{
  text-align: left;
}

.custom-modal .ant-modal-body{
  padding-top: 12px !important;
}